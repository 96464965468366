import React, { useState, useEffect, useRef } from "react";

const HideShowValue = (props) => {
  const [valueIsMasked, setValueIsMasked] = React.useState(true);

  return (
    <div
      className="card__item card__item--small card__item--light"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <div className="card__item__value__icon">
          <i className="solid solid-budicon-lock" />
        </div>
        <div className="card__item__value">
          {valueIsMasked
            ? "value is hidden - click button to show"
            : props.value}
        </div>
      </div>
      <div
        className="btn btn--blue btn--sq btn--ib"
        onClick={() => {
          setValueIsMasked(!valueIsMasked);
        }}
      >
        <div className="solid solid-budicon-eye-sight"></div>
      </div>
    </div>
  );
};

export default HideShowValue;
